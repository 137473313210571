import React from 'react';
import Header from '../layout/header';
import Footer from '../layout/footer';
import { Route } from 'react-router-dom';
import CommunicationLeft from './communicationLeft';
import PromotionalSMS from './promotionalSMS';
import SmsEmail from './smsEmail';
import PrepareSms from './prepareSms';
import PatientGroup from './patientGroup';
import HospitalPatientGroup from './hospitalPatientGroup';
import ComposeSms from './composeSms';
import { Outlet } from 'react-router-dom';
function communication() {
	return (
		<div className="coverWraper innerCoverWraper">
			<Header />
			<div className="contentWraper contentWraperScroll contentWhiteBg">
				<CommunicationLeft />
				<div className="settingsRight">
					{/* <Route path="/communications/promotionalSMS" component={PromotionalSMS} />
					<Route path="/communications/smsEmail" component={SmsEmail} />
					<Route path="/communications/prepareSms" component={PrepareSms} />
					<Route path="/communications/patientGroup" component={PatientGroup} />
					<Route path="/communications/hospitalPatientGroup" component={HospitalPatientGroup} />
					<Route path="/communications/composeSms" component={ComposeSms} /> */}
					<Outlet />
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default communication;
