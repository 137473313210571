import React, { useEffect } from "react";
import Editbg from "../images/icons/lab/labEdit.png";
import { callingAPI, callingAPI_GET } from "../config";
import { useState } from "react";
import { Link } from "react-router-dom";
import Loader from "../Common/loader";
import NoData from "../Common/noDataFound";
import Delete from "../images/icons/delete_btn.png";
import moment from 'moment';
import pdfImage from "../images/media/pdf.png"
import { useNavigate } from 'react-router-dom';


function PatientOldHistorylist() {
  const navigate = useNavigate();

  const [patientOldHistoryListing, setpatientOldHistoryListing] = useState([]);
  const [flag, setFlag] = useState(false);
  const [procedureCheckIds, setprocedureCheckIds] = useState([])

  const oldHistoryList = () => {
    setFlag(true);
    const listParams = {
      patientHistoryId: "",
      memberId: window.sessionStorage.getItem('memberId'),
      keyWord: "",
      offset: "0",
      max: "10",
    };
    callingAPI("patientsListing/getPatientHistory", listParams)
      .then((response) => {
        if (response.data.success === "1") {
          setpatientOldHistoryListing(response.data.result.patientHistoryData);
          setFlag(false);
        } else {
          setpatientOldHistoryListing([]);
          setFlag(false);
        }
      })
      .catch();
  };
  useEffect(() => {
    oldHistoryList();

  }, []);
  const pdfPopUP = (data) => {

    window.open(data.imageNameWithPath, '_blank');
  }
  const patientToldHistory = (data) => {
    navigate('/patients/patientInvoiceAdd', { state: data })
  }
  const deleteData = (data) => {
    var x = window.confirm("Do you want to remove?");
    if (x) {
      const postData = {
        patientHistoryId: data.patientHistoryId,
        isActive: false,
      };
      callingAPI("patientsListing/deletePatientData", postData).then(
        (response) => {
          if (response.data.success === "1") {
            oldHistoryList();
          }
        }
      );
    }
  };

  const editHistoryOld = (data) => {
    navigate('/patients/historyFromOtherVendorEdit', { state: data })
  }

  const previousBalnceClick = () => {

    var date = moment(new Date()).format('yyyy-MM-DD hh:mm A');

    var date2 = moment(new Date()).format('yyyy-MM-DD HH:mm:ss');
    var milliStart = moment(date).valueOf();
    var milliEnd = moment(date).add(30, 'm').format('yyyy-MM-DD h:mm A');
    var now = new Date();
    var utc = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
    const postData = {
      "userId": window.sessionStorage.getItem('userId'),
      "hidhospitalId": localStorage.getItem('hospitalId'),
      "hidMemberId": window.sessionStorage.getItem('memberId'),
      "hidDoctorId": window.sessionStorage.getItem('patientDoctorId'),
      "scheduledtimeSlotStartTime": milliStart,
      "scheduledtimeSlotEndTime": moment(milliEnd).valueOf(),
      "appDate": date,
      "medicinBranchName": window.sessionStorage.getItem('medicineBranchId') === "4" ? "Dentistry" : "Other",
      "hidMemberProcedureDentalOrderId": "",
      "hidTaxTotal": 0,
      "hidCostTotal": 0,
      "hidDiscountTotal": "0",
      "hidGrandTotal": 0,
      "hidTreatmentDetails": [
        {
          "hidProcedureId": "",
          "hidMemberProcedureDentalDetailsId": "",
          "hidTaxIndividual": 0,
          "treatmentCost": 0,
          "hidDiscountIndividual": "0",
          "hidGrandIndividual": 0,
          "notes": "",
          "quantity": "1",
          "memberProcedureDentalDetailsTeethNumber": "None Selected",
          "discountType": "INR",
          "discountAmount": "0",
          "memberProcedureDentalDetailsMultipleCostWithTeeth": 0,
          "treatmentName": "",
          "treatmentCode": "",
          "isConsentForm": 0,
          "showTeeth": 0,
          "fullMouth": 0,
          "dateOnShow": utc,
          "dateOn": date,
          "doctorObj": window.sessionStorage.getItem('patientDoctorId'),
          "doctorName": window.sessionStorage.getItem('patientDoctorName'),
          "isPaymentCompleted": "0",
          "inventoryDetails": [],
          "taxDetails": []
        }
      ],
      "hidRemoveIds": ""
    };
    callingAPI("commonUserFunctions/previousBalanceFromVendor", postData).then((response) => {
      var completedArray = []
      completedArray.push(response.data.result.memberProcedureDentalDetailId)
      setprocedureCheckIds(completedArray)

      if (document.getElementById('treatmentCompleteTick') != null) {
        document.getElementById('treatmentCompleteTick').click();

      }
    }
    );

  }
  const historyFromOtherVendorEditClick = (data) => {
    // navigate('/settings/addTax', { state: data })
    navigate('/patients/historyFromOtherVendorEdit', { state: data })
  }
  return (

    <div class="treatmentPlanWrap patientConsultInnerPgeLoadDiv_PTour">
      <div id="divPatientContent" class="sideMenuContent">
        <div
          class="treatmentPlanCover"
          id="treatmentPlanListDiv"
          style={{ border: "none" }}
        >
          <div class="treatmentPlanHead">
            <span class="treatmentPlanName">History From Other Vendor</span>

            <span onClick={() => historyFromOtherVendorEditClick({ editData: "", editFlag: false })}>
              <span
                title="Add Appointment"
                class="treatmentPlanBtn pl_blue_btn"
              >
                + Add
              </span>
            </span>
            {/* <a href="/patients/historyFromOtherVendorEdit"> */}
            <span
              title="Previous Balance"
              class="treatmentPlanBtn pl_blue_btn"
              //  id={'treatmentCompleteTick123'}
              onClick={() => previousBalnceClick()}
            >

              Previous Balance
            </span>
            <nav onClick={() => patientToldHistory({ procedurecheckedIds: procedureCheckIds, hospitalId: localStorage.getItem('hospitalId'), memberId: window.sessionStorage.getItem('memberId'), isFlag: "" })}
              id={'treatmentCompleteTick'} style={{ display: 'none' }}
            ></nav>
            {/* </a> */}
          </div>
          {flag === true ? (
            <Loader />
          ) : patientOldHistoryListing.length > 0 ? (
            <div class="treatmentPlanList" style={{ height: "auto" }}>
              <div>
                {patientOldHistoryListing.map((data) => (

                  <div class="appoinmentWrap" id="rows0">
                    {data.createdDateAndTime && (
                      <div class="appoinmentDateDiv appoinmentDateDiv-height">
                        <div>
                          <span class="apnmentDate" id="appDay139704">
                            {moment(parseInt(data.createdDateAndTime)).format('h:mm A')}
                          </span>
                          <span class="apnmentDay" id="appDay139704">
                            {moment(parseInt(data.createdDateAndTime)).format('DD-MMM-YYYY')}
                          </span>

                          {/* <span class="apnmentDay" id="monthyeartime139704">
                              {data.createdDateAndTime.split("-")[1]}{" "}
                              {data.createdDateAndTime.split("-")[2]}
                              {data.createdDateAndTime.split("-")[3]}
                            </span> */}
                        </div>
                      </div>
                    )}
                    <div class="appoinmentDateDetail">
                      <div class="appoinmentLeftDiv">
                        <span class="appoinmentReason">
                          <span>{data.title}</span>
                        </span>
                      </div>
                      <div class="appoinmentSatusWrap">
                        <span class="appoinmentTYpe oldhistory_descr">
                          {data.description}
                        </span>
                      </div>
                      <div class="oldhistoryimgeWrapr">
                        {data.patientHistoryImageArray.map((item) => (
                          <div className="oldhistoryimge">
                            {console.log("...................................ss", item)}
                            {item.imageNameWithPath.endsWith('.pdf') ?
                              <img
                                src={pdfImage}
                                title={item.imageNameOnly}
                                alt="oldHistoryImg"
                                onClick={() => {
                                  pdfPopUP(item);
                                }}
                                className="img-fluid oldhistoryimglist"
                              /> :
                              <img
                                src={item.imageNameWithPath}
                                title={item.imageNameOnly}
                                onClick={() => {
                                  pdfPopUP(item);
                                }}
                                alt="oldHistoryImg"
                                className="img-fluid oldhistoryimglist"
                              />
                            }

                          </div>
                        ))}
                      </div>
                    </div>
                    <div
                      class="patientoldHistoryEdit"
                      style={{ padding: "0px" }}
                    >
                      <span class="dentalProIcons">
                        <nav onClick={() => editHistoryOld({ editData: data, editFlag: true })}
                          className="oldhistry_editlink"
                        >
                          <img
                            title="Edit"
                            src={Editbg}
                            style={{ width: "20px" }}
                          />{" "}
                        </nav>
                      </span>
                      <span class="dentalProIcons">
                        <img
                          title="Delete"
                          onClick={() => {
                            deleteData(data);
                          }}
                          src={Delete}
                          style={{ width: "18px", cursor: "pointer" }}
                        />{" "}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <p>
              <NoData />
            </p>
          )}
        </div>
      </div>
    </div>

  );
}
export default PatientOldHistorylist;
