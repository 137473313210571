import './App.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { Switch } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.css"
import "../src/css/style.css"
import "../src/css/responsive.css"
import './index.css';

import Login from "./Authentication/login";
import ForgotPassword from "./Authentication/forgotPassword";
import MembersReceive from "./Authentication/membersReceive";
import ForgotPasswordOtp from "./Authentication/forgotPasswordOtp";
import LoginLoader from "./Authentication/loginLoading";
import About from "./Authentication/About"
import TermsAndConditions from "./Authentication/terms&conditions"
import PrivacyPolicy from "./Authentication/privacypolicy"
import PrescriptiomMessage from "./Authentication/prescriptionMessage"


import LoginHeader from "./layout/loginHeader";

import Welcome from "./Dashboard/welcome";
import Profile from "./Dashboard/profile";
import Changepassword from "./Dashboard/changepassword";
import DashboardMain from "./Dashboard/dashboardMain";
import HealthInfoDetails from "./Dashboard/healthInfoDetails";
import Help from "./Dashboard/help";



import Report from './Reports/reports';
import Patientdetails from "./Reports/patientdetails";


import Communicationtab from './Communications/communication';
import PromotionalSMS from "./Communications/promotionalSMS";
import Preparesms from "./Communications/prepareSms";
import PatientGroup from "./Communications/patientGroup";

import SmsEmail from "./Communications/smsEmail";
import HospitalPatientGroup from "./Communications/hospitalPatientGroup";
import ComposeSms from "./Communications/composeSms";




import Healthcard from "./Settings/healthcard";
import Settings from './Settings/settings';

import AddInventory from './Settings/addInventory';
import AddHospitalExpenses from './Settings/addHospitalExpenses';
import AddPurchase from "./Settings/addPurchase";
import AddMedicine from "./Settings/addMedicine";

import EditHospitalExpenses from './Settings/editHospitalExpenses';
import EditInventory from "./Settings/editInventory";
import EditPurchaseDetails from "./Settings/editPurchaseDetails";
import EditMedicine from "./Settings/editMedicine";

import NewBatchInventory from "./Settings/newBatchInventory";
import NewBatchMedicine from "./Settings/newBatchMedicine";

import HeaderPrint from "./Settings/headerPrint";
import FooterPrint from "./Settings/footerPrint";
import HeaderDoctorPrescriptionPrint from "./Settings/headerDoctorPrescriptionPrint";
import FooterDoctorPrescriptionPrint from "./Settings/footerDoctorPrescriptionPrint";

// import Healthcard from "./Settings/healthcard";


import LabOrderDetails from './labLogin/labOrderDetails';
import LabTestBill from './labLogin/labTestBill';
import LabOrderPayment from './labLogin/labOrderPayment';
import LabTestUnavailability from './labLogin/labTestUnavailability';
import OutsideLabOrderDetails from './labLogin/outsideLabOrderDetails';
import OutsideLabOrderDetailsView from './labLogin/outsideLabOrderDetailsView';
import LabAddCollectionDate from './labLogin/labAddCollectionDate';
import AddLabTestDetails from './labLogin/addLabTestDetails';
import LabTestParametersLab from './labLogin/labTestParametersLab';
import LabTestPrint from './labLogin/labTestPrint';
import TestResultPrint from './labLogin/testResultPrint';


import PrescriptionDetails from './pharmacyLogin/prescriptionDetails';
import PrescriptionBill from './pharmacyLogin/prescriptionBill';
// import SupplierInvoice from './pharmacyLogin/supplierInvoice';
import PharmacyStockDetails from './pharmacyLogin/pharmacyStockDetails';
import MedicineReorder from './pharmacyLogin/medicineReorder';
import ExpiredMedicineStockDetails from './pharmacyLogin/expiredMedicineStockDetails';
import MedicineSalebyDay from './pharmacyLogin/medicineSalebyDay';

import PatientMedicine from './pharmacyLogin/patientMedicine';
import PatientMedicineView from './pharmacyLogin/patientMedicineView';
import PrescribedMedicinebyQuantity from './pharmacyLogin/prescribedMedicinebyQuantity';
import PrescriptionAmountView from './pharmacyLogin/prescriptionAmountView';
import OutsidePharmacyOrderDetails from './pharmacyLogin/outsidePharmacyOrderDetails';
import OutsidePharmacyOrderDetailsView from './pharmacyLogin/outsidePharmacyOrderDetailsView';
import UnavailableMedicine from './pharmacyLogin/unavailableMedicine';
import HospitalMedicineHistory from './pharmacyLogin/hospitalMedicineHistory';
import PrescriptionBillPrint from './Invoice/prescriptionBillPrint';
import PrescriptionDetailsPrint from './pharmacyLogin/prescriptionDetailsPrint';

import PharmacyManufacture from "./Settings/pharmacyManufacture";
import PharmacySupplier from "./Settings/pharmacySupplier";
import PharmacySupplierReports from "./Reports/supplierInvoice";
import PharmacystockdetailsReports from "./Reports/pharmacystockdetails";
// import PharmacyPaymentsReports from "./Reports/pharmacypayment";
import PharmacyprescriptionAmountReports from "./Reports/prescriptionAmount";
import PrescriptionAmountDetails from "./Reports/prescriptionAmountdetails";
// import PharmacyPurchaseLists from "./Settings/purchaseLists";
import PharmacyhospitalInventory from "./Settings/hospitalInventory";
import PharmacymedicineStockUpdate from "./Settings/medicineStockUpdate";



import LabOrderDetailsBill from './billingLogin/labOrderDetails';
import BlankPage from './billingLogin/blankPage';
import ProcedurePayment from './billingLogin/procedurePayment';


import ConsentFormInvoiceSettings from "./Invoice/consentFormInvoiceSettings";


// import Calendar from "./Clinics/calendar";
import StaffList from "./Clinics/staffList";
import StaffAdd from "./Clinics/staffAdd";
import StaffEdit from "./Clinics/staffEdit";
import StaffSetPermission from "./Clinics/staffSetPermission";
import TreatmentChart from "./Clinics/treatmentChart";
import TreatmentChartPrint from "./Clinics/treatmentChartPrint";
import ClinicsCalendar from "./Clinics/clinicsCalendar";
import BlockcalenderPopup from "./Clinics/blockcalenderPopup";
import ExportCalendar from "./Clinics/exportCalendar";

import PatientListing from "./Patients/patientListing";
import Patientsprofile from "./Patients/profile";
import AddPatient from "./Patients/addPatient";
import Appointment from "./Patients/appointment";
import Patientconsult from "./Patients/patientconsult";
import TreatmentPlans from "./Patients/treatmentPlans";
import AddTreatmentPlans from './Patients/addTreatmentPlans';
import PatientAppointments from "./Patients/patientAppointments";
import PatientOldHistory from "./Patients/patientOldHistory";
import PatientoldhistoryEdit from "./Patients/patientoldhistoryEdit";
import PatientAppointmentEdit from "./Patients/patientAppointmentEdit";
import CompletedPlans from './Patients/completedPlans';
import MedicalCertificate from './Patients/medicalCertificate';
import MedicalCertificateEdit from './Patients/medicalCertificateEdit';
import AddCompletedPlans from './Patients/addCompletedPlans';
import PatientPayments from "./Patients/patientPayments";
import PatientPaymentRefundInvoice from "./Patients/patientPaymentRefundInvoice";
import PatientPaymentInvoice from "./Patients/patientPaymentInvoice";
import AddPayments from "./Patients/addPayments";
import AddPaymentsRefund from "./Patients/addPaymentsRefund";
import PatientHealthcard from "./Patients/patientHealthcard";
import History from "./Patients/history";
import LabDetailsView from "./Patients/labDetailsView";
import AddToGroup from "./Patients/addToGroup";
import InvoiceNotInHospitalPrint from "./Patients/invoiceNotInHospitalPrint";
import PatientInvoices from "./Patients/patientInvoices";
import PatientInvoicePrint from "./Patients/patientInvoicePrint";
import PatientInvoiceAdd from "./Patients/patientInvoiceAdd";
import LabOrder from "./Patients/labOrder";
import LabOrderAdd from "./Patients/labOrderAdd";
import CreateInvoiceSelectedTreatment from "./Patients/createInvoiceSelectedTreatment";
import PatientPriscription from "./Patients/patientPriscription";
import PatientPriscriptionAdd from "./Patients/patientPriscriptionAdd";
import DrugsRight from "./Patients/drugsRight";
import PatientPriscriptionWrite from "./Patients/patientPriscriptionWrite";
import CreateInvoiceSelectedMedicine from "./Patients/createInvoiceSelectedMedicine";
import ConsentFormInvoice from "./Patients/consentFormInvoice";
import CasesheetDental from "./Patients/casesheetDental";
import CasesheetMedical from "./Patients/casesheetMedical";
import CasesheetDentalHistory from "./Patients/casesheetDentalHistory";
import CasesheetDentalAdd from "./Patients/casesheetDentalAdd";
import CasesheetMedicalHistory from "./Patients/casesheetMedicalHistory";
import CasesheetMedicalAdd from "./Patients/casesheetMedicalAdd";
import CasesheetMedicalPrint from "./Patients/casesheetMedicalPrint";
import CasesheetDentalPrint from "./Patients/casesheetDentalPrint";
import CasesheetMedicalAddWrite from "./Patients/casesheetMedicalAddWrite";
import TreatmentPlansPrint from "./Patients/treatmentPlansPrint";
import CompletedPlansPrint from "./Patients/completedPlansPrint";
import LabOrderPrint from "./Patients/labOrderPrint";
import PriscriptionPrint from "./Patients/priscriptionPrint";
import PriscriptionImgPrint from "./Patients/priscriptionImgPrint";
import InvoicePayNow from "./Patients/invoicePayNow";
import InvoicePaymentList from "./Patients/invoicePaymentList";
import LabOrderDental from "./Patients/labOrderDental";
import LabOrderDentalAdd from "./Patients/labOrderDentalAdd";
import TabsRight from "./Patients/tabsRight";
import TestResult from "./Patients/testResult";
import TestResultPrintAll from "./Patients/testResultPrintAll";
import TestResultAdd from "./Patients/testResultAdd";
import LabOrderRight from "./Patients/labOrderRight";
import PatientFiles from "./Patients/patientFiles";
import PatientFilesAdd from "./Patients/patientFilesAdd";
import PatientFilesMedical from "./Patients/patientFilesMedical";
import PatientFilesMedicalView from "./Patients/patientFilesMedicalView";
import PatientFilesRight from "./Patients/patientFilesRight";
import RecieptPrint from './Patients/RecieptPrint';
import LabOrderDentalPrint from './Patients/labOrderDentalPrint';
import LabOrderOrthoCreation from './Patients/labOrderOrthoCreation';
import LabOrderMetalFreeCeramic from './Patients/labOrderMetalFreeCeramic';
import LabOrderMetalCeramic from './Patients/labOrderMetalCeramic';
import HistoryPrint from './Patients/historyPrint';
import SimplifiedForm from './Patients/simplifiedForm';
import HistoryInfo from './Patients/historyInfo';
import SimplifiedFormLabOrder from './Patients/simplifiedFormLabOrder';
import SimplifiedFormCasesheet from './Patients/simplifiedFormCasesheet';
import SimplifiedFormPrint from './Patients/simplifiedFormPrint';
import SimplifiedFormPrintNew from './Patients/simplifiedFormPrintNew';


import SimplifiedFormPrescription from './Patients/simplifiedFormPrescription';
import SimplifiedFormPrescriptionRight from './Patients/simplifiedFormPrescriptionRight';
import PatientTestResultPrint from './Patients/patientTestResultPrint';
import MedicalCertificatePrint from './Patients/medicalCertificatePrint';
import VitalSigns from './Patients/vitalSigns';
import VitalSignsPrint from './Patients/vitalSignsPrint';
import VitalSignsAdd from './Patients/vitalSignsAdd';
import ShareData from './Patients/shareData';
import RefereToaDoctor from './Patients/refereToaDoctor';
import ReferToDoctorPrint from './Patients/referToDoctorPrint';
import VitalGraph from './Patients/vitalGraph';
import ShareDataConsentForm from './Patients/shareDataConsentForm';
import HospitalHome from "./Hospital Admin/hospitalHome";
import DoctorsList from "./Doctor/doctorsList";
import DoctorListAdd from "./Doctor/doctorListAdd";
import store from "./Redux/store"
import Pharmacy from './pharmacyLogin/pharmacy';
import Billing from './billingLogin/billing';
import PrescriptionDetailsPrint1 from './pharmacyLogin/prescriptionDetailsPrint';
import LabTestBillPrint from './labLogin/labTestBillPrint';
import DoctorReference from './Reports/doctorReference';
import MedicineByDoctor from './Reports/medicineByDoctor';
import AppointmentList from './Reports/appointmentList';
import LabTestInventory from './Reports/labTestInventory';
import TreatmentDetails from './Reports/treatmentDetails';
import ReceiptTaxDetails from './Reports/receiptTaxDetails';
import GenericMedicineList from './Reports/genericMedicineList';
import PurchaseInvoiceDetails from './Reports/purchaseInvoiceDetails'
import IncomeExpenseInvoice from './Reports/incomeExpenseInvoice'
import IncomeExpense from './Reports/incomeExpense'
import Pendingpaymentdetails from './Reports/pendingpaymentdetails'
import Patientpayment from './Reports/patientpayment';
import Refund from './Reports/refund';
import RevenueByDoctor from './Reports/revenueByDoctor';

import PracticeDetails from './Settings/practiceDetails';
import DashboardView from './Dashboard/dashboardView';
import TotalPendingPayment from './Dashboard/totalPendingPayment';
import UpcomingAppoiments from './Dashboard/dashboardView/upcomingAppoiments';

import LabOrderList from './LabOrder/labOrderList'
import LabOrderSave from './LabOrder/labOrder'

import ProcedureBill from './billingLogin/procedureBill';
import ReportsPayment from './Reports/pharmacypayment';

import { Provider } from "react-redux"
import PurchaseLists from './Settings/purchaseLists';

import TreatmentProcedure from './Settings/treatmentProcedure'
import AddTreatmentProcedure from './Settings/addProcedure'
import SimplifiedFormSettings from './Settings/simplifiedFormSettings'
import AutoIdGeneration from './Settings/autoIdGeneration'
import DoctorPrescription from './Settings/doctorPrescription'
import LabTestDetails from './Settings/labTestDetails'
import AddSpecimenType from './Settings/addSpecimenType'
import LabTestType from './Settings/labTestType'
import AddLab from './Settings/addLab'
import AddWorkType from './Settings/addWorkType'
import AddLabWork from './Settings/addLabWork'
import Consentform from './Settings/consentform'
import TaxDetails from './Settings/taxDetails'
import AddTax from './Settings/addTax'
import Printouts from './Settings/printouts'
import Emails from './Settings/emails'
import ToDoList from './Settings/toDoList'
import CasesheetSettings from './Settings/casesheetSettings'
import HospitalExpenses from './Settings/hospitalExpenses';


function App() {
	return (
		<Provider store={store}>
			<Router>
				<Routes>
					{/* <Switch> */}
					<Route path="/" element={<Login />} exact />

					<Route path="/pharmacyLogin/prescriptionDetailsPrint" element={<PrescriptionDetailsPrint1 />} />

					<Route path="/Authentication/forgotPassword" element={<ForgotPassword />} />
					<Route path="/layout/loginHeader" element={<LoginHeader />} />
					<Route path="/Authentication/membersReceive" element={<MembersReceive />} />
					<Route path="/Authentication/forgotPasswordOtp/:siteIdOtp?/:userIdOtp?" element={<ForgotPasswordOtp />} />

					<Route path="/Dashboard/welcome" element={<Welcome />} />
					<Route path="/Dashboard/profile" element={<Profile />} />
					<Route path="/Dashboard/changepassword" element={<Changepassword />} />
					<Route path="/Dashboard/dashboardMain" element={<DashboardMain />} />
					<Route path="/Dashboard/healthInfoDetails" element={<HealthInfoDetails />} />
					<Route path="/Dashboard/help" element={<Help />} />
					<Route path="/labLogin/labTestBillPrint" element={<LabTestBillPrint />} />


					<Route path="/reports" element={<Report />} >
						<Route path="/reports/supplierInvoice" element={<PharmacySupplierReports />} />
						<Route path="/reports/pharmacystockdetails" element={<PharmacystockdetailsReports />} />
						<Route path="/reports/pharmacypayment" element={<ReportsPayment />} />
						<Route path="/reports/appointmentList" element={<AppointmentList />} />
						<Route path="/reports/patientdetails" element={<Patientdetails />} />
						<Route path="/reports/labTestInventory" element={<LabTestInventory />} />
						<Route path="/reports/genericMedicineList" element={<GenericMedicineList />} />
						<Route path="/reports/purchaseInvoiceDetails" element={<PurchaseInvoiceDetails />} />
						<Route path="/reports/incomeExpenseInvoice" element={<IncomeExpenseInvoice />} />
						<Route path="/reports/incomeExpense" element={<IncomeExpense />} />
						<Route path="/reports/pendingpaymentdetails" element={<Pendingpaymentdetails />} />
						<Route path="/reports/patientpayment" element={<Patientpayment />} />
						<Route path="/reports/refund" element={<Refund />} />
						<Route path="/reports/prescriptionAmount" element={<PharmacyprescriptionAmountReports />} />
						<Route path="/reports/revenueByDoctor" element={<RevenueByDoctor />} />
						<Route path="/reports/treatmentDetails" element={<TreatmentDetails />} />
						<Route path="/reports/doctorReference" element={<DoctorReference />} />
						<Route path="/reports/medicineByDoctor" element={<MedicineByDoctor />} />
						<Route path="/reports/receiptTaxDetails" element={<ReceiptTaxDetails />} />
					</Route>






					<Route path="/Clinics/calendar" element={<ClinicsCalendar />} />
					<Route path="/clinics/staffList" element={<StaffList />} />
					<Route path="/clinics/staffAdd" element={<StaffAdd />} />
					<Route path="/clinics/staffEdit" element={<StaffEdit />} />
					<Route path="/clinics/staffSetPermission" element={<StaffSetPermission />} />
					<Route path="/clinics/treatmentChart" element={<TreatmentChart />} />
					<Route path="/clinics/treatmentChartPrint" element={<TreatmentChartPrint />} />
					<Route path="/Clinics/clinicsCalendar" element={<ClinicsCalendar />} />
					<Route path="/Clinics/blockcalenderPopup" element={<BlockcalenderPopup />} />
					<Route path="/Clinics/exportCalendar" element={<ExportCalendar />} />

					<Route path="/communications" element={<Communicationtab />} >
						{/* <Route path="/communications" element={<Communicationtab />} /> */}
						<Route path="/communications/promotionalSMS" element={<PromotionalSMS />} />
						<Route path="/communications/prepareSms" element={<Preparesms />} />
						<Route path="/communications/patientGroup" element={<PatientGroup />} />

						<Route path="/communications/smsEmail" element={<SmsEmail />} />
						<Route path="/communications/hospitalPatientGroup" element={<HospitalPatientGroup />} />
						<Route path="/communications/composeSms" element={<ComposeSms />} />
					</Route>

					{/* <Route path="/settings/practiceDetails" element={<PracticeDetails />} /> */}
					<Route path="/settings/headerDoctorPrescriptionPrint" element={<HeaderDoctorPrescriptionPrint />} />
					<Route path="/settings/footerDoctorPrescriptionPrint" element={<FooterDoctorPrescriptionPrint />} />
					<Route path="/settings/headerPrint" element={<HeaderPrint />} />
					<Route path="/settings/footerPrint" element={<FooterPrint />} />


					<Route path="/settings" element={<Settings />} >
						<Route path="/settings/practiceDetails" element={<PracticeDetails />} />
						<Route path="/settings/treatmentProcedure" element={<TreatmentProcedure />} />
						<Route path="/settings/addProcedure" element={<AddTreatmentProcedure />} />
						<Route path="/settings/simplifiedFormSettings" element={<SimplifiedFormSettings />} />
						<Route path="/settings/autoIdGeneration" element={<AutoIdGeneration />} />
						<Route path="/settings/doctorPrescription" element={<DoctorPrescription />} />
						<Route path="/settings/pharmacySupplier" element={<PharmacySupplier />} />
						<Route path="/settings/pharmacyManufacture" element={<PharmacyManufacture />} />
						<Route path="/settings/labTestDetails" element={<LabTestDetails />} />
						<Route path="/settings/addSpecimenType" element={<AddSpecimenType />} />
						<Route path="/settings/labTestType" element={<LabTestType />} />
						<Route path="/settings/addLab" element={<AddLab />} />
						<Route path="/settings/addWorkType" element={<AddWorkType />} />
						<Route path="/settings/addLabWork" element={<AddLabWork />} />
						<Route path="/settings/consentform" element={<Consentform />} />
						<Route path="/settings/taxDetails" element={<TaxDetails />} />
						<Route path="/settings/addTax" element={<AddTax />} />
						<Route path="/settings/healthcard" element={<Healthcard />} />
						<Route path="/settings/printouts" element={<Printouts />} />
						<Route path="/settings/emails" element={<Emails />} />
						<Route path="/settings/toDoList" element={<ToDoList />} />
						<Route path="/settings/casesheetSettings" element={<CasesheetSettings />} />
						<Route path="/settings/medicineStockUpdate" element={<PharmacymedicineStockUpdate />} />
						<Route path="/settings/purchaseLists" element={<PurchaseLists />} />
						<Route path="/settings/hospitalInventory" element={<PharmacyhospitalInventory />} />
						<Route path="/settings/hospitalExpenses" element={<HospitalExpenses />} />

						<Route path="/settings/addInventory" element={<AddInventory />} />
						<Route path="/settings/addHospitalExpenses" element={<AddHospitalExpenses />} />
						<Route path="/settings/addMedicine" element={<AddMedicine />} />
						<Route path="/settings/addPurchase" element={<AddPurchase />} />
						<Route path="/settings/editHospitalExpenses" element={<EditHospitalExpenses />} />
						<Route path="/settings/editInventory" element={<EditInventory />} />
						<Route path="/settings/editMedicine" element={<EditMedicine />} />
						<Route path="/settings/editPurchaseDetails" element={<EditPurchaseDetails />} />

						<Route path="/settings/newBatchInventory" element={<NewBatchInventory />} />
						<Route path="/settings/newBatchMedicine" element={<NewBatchMedicine />} />

					</Route>

					<Route path="/invoice/consentFormInvoiceSettings" element={<ConsentFormInvoiceSettings />} />
					<Route path="/invoice/prescriptionBillPrint" element={<PrescriptionBillPrint />} />
					<Route path="/labLogin/labOrderPayment" element={<LabOrderPayment />} />
					<Route path="/labLogin/labTestUnavailability" element={<LabTestUnavailability />} />
					<Route path="/labLogin/outsideLabOrderDetails" element={<OutsideLabOrderDetails />} />
					<Route path="/labLogin/outsideLabOrderDetailsView" element={<OutsideLabOrderDetailsView />} />
					<Route path="/labLogin/labAddCollectionDate" element={<LabAddCollectionDate />} />
					<Route path="/labLogin/addLabTestDetails" element={<AddLabTestDetails />} />
					<Route path="/labLogin/labTestParametersLab" element={<LabTestParametersLab />} />
					<Route path="/labLogin/labTestPrint" element={<LabTestPrint />} />
					<Route path="/labLogin/testResultPrint" element={<TestResultPrint />} />
					<Route path="/reports/prescriptionAmountdetails" element={<PrescriptionAmountDetails />} />

					<Route path="/pharmacyLogin" element={<Pharmacy />} >
						<Route path="/pharmacyLogin/prescriptionDetails" element={<PrescriptionDetails />} />
						<Route path="/pharmacyLogin/prescriptionBill" element={<PrescriptionBill />} />
						<Route path="/pharmacyLogin/pharmacyStockDetails" element={<PharmacyStockDetails />} />
						<Route path="/pharmacyLogin/medicineReorder" element={<MedicineReorder />} />
						<Route path="/pharmacyLogin/expiredMedicineStockDetails" element={<ExpiredMedicineStockDetails />} />
						<Route path="/pharmacyLogin/medicineSalebyDay" element={<MedicineSalebyDay />} />
						<Route path="/pharmacyLogin/patientMedicine" element={<PatientMedicine />} />
						<Route path="/pharmacyLogin/patientMedicineView" element={<PatientMedicineView />} />
						<Route path="/pharmacyLogin/prescribedMedicinebyQuantity" element={<PrescribedMedicinebyQuantity />} />
						<Route path="/pharmacyLogin/prescriptionAmountView" element={<PrescriptionAmountView />} />
						<Route path="/pharmacyLogin/outsidePharmacyOrderDetails" element={<OutsidePharmacyOrderDetails />} />
						<Route path="/pharmacyLogin/outsidePharmacyOrderDetailsView" element={<OutsidePharmacyOrderDetailsView />} />
						<Route path="/pharmacyLogin/unavailableMedicine" element={<UnavailableMedicine />} />
						<Route path="/pharmacyLogin/hospitalMedicineHistory" element={<HospitalMedicineHistory />} />
						<Route path="/pharmacyLogin/prescriptionDetailsPrint" element={<PrescriptionDetailsPrint />} />
					</Route>



					<Route path="/billingLogin" element={<Billing />} >
						<Route path="/billingLogin/procedurePayment" element={<ProcedurePayment />} />
						<Route path="/billingLogin/procedureBill" element={<ProcedureBill />} />
					</Route>
					<Route path="labLogin/labTestBill" element={<LabTestBill />} />
					<Route path="labLogin/labOrderDetails" element={<LabOrderDetails />} />
					<Route path="/billingLogin/labOrderDetails" element={<LabOrderDetailsBill />} />
					<Route path="/billingLogin/blankPage" element={<BlankPage />} />



					<Route path="/patients/patientListing" element={<PatientListing />} />
					<Route path="/patients/profile" element={<Patientsprofile />} />
					<Route path="/patients/addPatient" element={<AddPatient />} />
					<Route path="/patients/appointment" element={<Appointment />} />
					<Route path="/patients/patientconsult/:showEdit?" element={<Patientconsult />} />
					<Route path="/patients/treatmentPlans" element={<TreatmentPlans />} />
					<Route path="/patients/addTreatmentPlans" element={<AddTreatmentPlans />} />
					<Route path="/patients/patientAppointments" element={<PatientAppointments />} />
					<Route path="/patients/patientAppointmentEdit" element={<PatientAppointmentEdit />} />
					<Route path="/patients/completedPlans" element={<CompletedPlans />} />
					<Route path="/patients/medicalCertificate" element={<MedicalCertificate />} />
					<Route path="/patients/medicalCertificateEdit" element={<MedicalCertificateEdit />} />
					<Route path="/patients/addCompletedPlans" element={<AddCompletedPlans />} />
					<Route path="/patients/patientPayments" element={<PatientPayments />} />
					<Route path="/patients/patientPaymentRefundInvoice" element={<PatientPaymentRefundInvoice />} />
					<Route path="/patients/patientPaymentInvoice" element={<PatientPaymentInvoice />} />
					<Route path="/patients/addPayments" element={<AddPayments />} />
					<Route path="/patients/addPaymentsRefund" element={<AddPaymentsRefund />} />
					<Route path="/patients/patientHealthcard" element={<PatientHealthcard />} />
					<Route path="/patients/history" element={<History />} />
					<Route path="/patients/labDetailsView" element={<LabDetailsView />} />
					<Route path="/patients/addToGroup" element={<AddToGroup />} />
					<Route path="/patients/invoiceNotInHospitalPrint" element={<InvoiceNotInHospitalPrint />} />
					<Route path="/patients/patientInvoices" element={<PatientInvoices />} />
					<Route path="/patients/patientInvoicePrint/:printId?" element={<PatientInvoicePrint />} />
					<Route path="/patients/patientInvoiceAdd" element={<PatientInvoiceAdd />} />
					<Route path="/patients/labOrder" element={<LabOrder />} />
					<Route path="/patients/labOrderAdd" element={<LabOrderAdd />} />
					<Route path="/patients/createInvoiceSelectedTreatment" element={<CreateInvoiceSelectedTreatment />} />
					<Route path="/patients/patientPriscription/:rxPrint?" element={<PatientPriscription />} />
					<Route path="/patients/patientPriscriptionAdd" element={<PatientPriscriptionAdd />} />
					<Route path="/patients/drugsRight" element={<DrugsRight />} />
					<Route path="/patients/patientPriscriptionWrite" element={<PatientPriscriptionWrite />} />
					<Route path="/patients/createInvoiceSelectedMedicine" element={<CreateInvoiceSelectedMedicine />} />
					<Route path="/patients/consentFormInvoice/:hospitalId?/:memberId?/:doctorId?" element={<ConsentFormInvoice />} />
					<Route path="/patients/casesheetDental" element={<CasesheetDental />} />
					<Route path="/patients/casesheetMedical" element={<CasesheetMedical />} />
					<Route path="/patients/casesheetDentalHistory" element={<CasesheetDentalHistory />} />
					<Route path="/patients/casesheetDentalAdd" element={<CasesheetDentalAdd />} />
					<Route path="/patients/casesheetMedicalHistory" element={<CasesheetMedicalHistory />} />
					<Route path="/patients/casesheetMedicalAdd" element={<CasesheetMedicalAdd />} />
					<Route path="/patients/casesheetMedicalPrint/:membId?/:doctorId?/:printId?/:urlHospId" element={<CasesheetMedicalPrint />} />
					<Route path="/patients/casesheetDentalPrint/:membId?/:doctorId?/:printId?/:urlHospId" element={<CasesheetDentalPrint />} />
					<Route path="/patients/casesheetMedicalAddWrite" element={<CasesheetMedicalAddWrite />} />
					<Route path="/patients/treatmentPlansPrint/:printId?/:hospitalId?" element={<TreatmentPlansPrint />} />
					<Route path="/patients/completedPlansPrint/:printId?" element={<CompletedPlansPrint />} />
					<Route path="/patients/labOrderPrint/:orderTestId?/:hospId?/" element={<LabOrderPrint />} />
					<Route path="/patients/priscriptionPrint/:printId?/:hospId?/:doctorId?/:tp?/:tw?" element={<PriscriptionPrint />} />
					<Route path="/patients/priscriptionImgPrint" element={<PriscriptionImgPrint />} />
					<Route path="/patients/invoicePayNow" element={<InvoicePayNow />} />
					<Route path="/patients/invoicePaymentList" element={<InvoicePaymentList />} />
					<Route path="/patients/labOrderDental" element={<LabOrderDental />} />
					<Route path="/patients/labOrderDentalAdd" element={<LabOrderDentalAdd />} />
					<Route path="/patients/tabsRight" element={<TabsRight />} />
					<Route path="/patients/testResult" element={<TestResult />} />
					<Route path="/patients/testResultPrintAll" element={<TestResultPrintAll />} />
					<Route path="/patients/testResultAdd" element={<TestResultAdd />} />
					<Route path="/patients/labOrderRight" element={<LabOrderRight />} />
					<Route path="/patients/patientFiles" element={<PatientFiles />} />
					<Route path="/patients/patientFilesAdd" element={<PatientFilesAdd />} />
					<Route path="/patients/patientFilesMedical" element={<PatientFilesMedical />} />
					<Route path="/patients/patientFilesMedicalView" element={<PatientFilesMedicalView />} />
					<Route path="/patients/patientFilesRight" element={<PatientFilesRight />} />
					<Route path="/patients/labOrderDentalPrint" element={<LabOrderDentalPrint />} />
					<Route path="/patients/labOrderOrthoCreation" element={<LabOrderOrthoCreation />} />
					<Route path="/patients/labOrderMetalFreeCeramic" element={<LabOrderMetalFreeCeramic />} />
					<Route path="/patients/labOrderMetalCeramic" element={<LabOrderMetalCeramic />} />
					<Route path="/patients/historyPrint" element={<HistoryPrint />} />
					<Route path="/patients/simplifiedForm" element={<SimplifiedForm />} />
					<Route path="/patients/historyInfo" element={<HistoryInfo />} />
					<Route path="/patients/simplifiedFormPrint" element={<SimplifiedFormPrint />} />
					<Route path="/patients/simplifiedFormPrintNew/:printId?" element={<SimplifiedFormPrintNew />} />
					<Route path="/patients/simplifiedFormPrescriptionRight" element={<SimplifiedFormPrescriptionRight />} />
					<Route path="/patients/patientTestResultPrint" element={<PatientTestResultPrint />} />
					<Route path="/patients/medicalCertificatePrint/:printId?" element={<MedicalCertificatePrint />} />
					<Route path="/patients/vitalSigns" element={<VitalSigns />} />
					<Route path="/patients/vitalSignsPrint/:printId?" element={<VitalSignsPrint />} />
					<Route path="/patients/vitalSignsAdd" element={<VitalSignsAdd />} />
					<Route path="/patients/shareData" element={<ShareData />} />
					<Route path="/patients/refereToaDoctor" element={<RefereToaDoctor />} />
					<Route path="/patients/referToDoctorPrint" element={<ReferToDoctorPrint />} />
					<Route path="/patients/vitalGraph" element={<VitalGraph />} />
					<Route path="/patients/shareDataConsentForm" element={<ShareDataConsentForm />} />
					<Route path="/patients/historyFromOtherVendor" element={<PatientOldHistory />} />
					<Route path="/patients/historyFromOtherVendorEdit" element={<PatientoldhistoryEdit />} />

					<Route path="/aboutus" element={<About />} />
					<Route path="/termsAndConditions" element={<TermsAndConditions />} />
					<Route path="/privacyPolicy" element={<PrivacyPolicy />} />

					<Route path="/hospital Admin/hospitalHome" element={<HospitalHome />} />
					<Route path="/doctor/doctorsList" element={<DoctorsList />} />
					<Route path="/doctor/doctorListAdd" element={<DoctorListAdd />} />
					<Route path="/patients/RecieptPrint/:typeId?/:printId?" element={<RecieptPrint />} />

					<Route path="/Dashboard/dashboardView" element={<DashboardView />} />
					<Route path="/totalPendingPayment" element={<TotalPendingPayment />} />
					<Route path="/upcomingAppoiments" element={<UpcomingAppoiments />} />
					<Route path="/labOrderList" element={<LabOrderList />} />
					<Route path="/labOrder" element={<LabOrderSave />} />
					{/* 
					<Route path="/T=:token" element={<PrescriptiomMessage />} exact />
					<Route path="/GROUP-:siteId" element={<Login />} exact />
					<Route path="/:siteId?/patients/priscriptionPrint/:printId?/:hospId?/:doctorId?/:tp?/:tw?" element={<PriscriptionPrint />} exact />
					<Route path="/:token?" element={<Login />} exact />
					<Route path="/:siteId?/T=:token" element={<PrescriptiomMessage />} exact />
					<Route path="/:siteId?/:token?" element={<LoginLoader />} exact /> */}

					<Route path="/group-:siteId" element={<Login />} exact /> {/* Adjusted to lowercase */}
					<Route path="/:siteId?/patients/priscriptionPrint/:printId?/:hospId?/:doctorId?/:tp?/:tw?" element={<PriscriptionPrint />} exact />
					<Route path="/:token?" element={<Login />} exact />
					<Route path="/:siteId?/T=:token" element={<PrescriptiomMessage />} exact />
					{/* <Route path="/:siteId?/rha?T=:token" element={<PrescriptiomMessage />} exact /> */}
					<Route path="/:siteId?/:token?" element={<LoginLoader />} exact />

				</Routes>
				{/* </Switch> */}
			</Router>
		</Provider>
	);
}

export default App;
